/* temporary overrides since Bootstrap and Paper are annoying af*/

p,
li {
    strong {
        font-weight: 700;
    }
}

.c-calendar {
    background-color: #ffffff;
    border-radius: 0.25rem;
    box-shadow: 0 16px 16px #00000015;
    max-width: 60rem;
    padding: 0.5rem;

    .fc-toolbar > .fc-center {
        text-align: center;

        > div {
            float: none !important;
            margin-left: 0 !important;

            &.title-month {
                color: #4e4e4e;
                font-size: 3rem;
                line-height: 2rem;
                @include museo-display;
            }

            &.title-year {
                color: #8c8c8c;
            }
        }
    }
}
