.card {
    background-color: #ffffff;
    border: 0 none;
    border-radius: $border-radius-extreme;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    color: $card-black-color;
    margin-bottom: 20px;
    position: relative;

    -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;

    .card-body {
        padding: 15px 15px 10px 15px;

        &.table-full-width {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-header {
        padding-bottom: 1.25rem;
        border: 0 0 1px 0;
        padding: 15px 15px 0;
        &:not([data-background-color]) {
            background-color: transparent;
        }

        .card-title {
            margin-top: 10px;
        }

    }

    .map {
        border-radius: $border-radius-small;

        &.map-big {
            height: 400px;
        }
    }

    &[data-background-color='orange'] {
        background-color: $primary-color;

        .card-header {
            background-color: $primary-color;
        }

        .card-footer {
            .stats {
                color: $white-color;
            }
        }
    }

    &[data-background-color='red'] {
        background-color: $danger-color;
    }

    &[data-background-color='yellow'] {
        background-color: $warning-color;
    }

    &[data-background-color='blue'] {
        background-color: $info-color;
    }

    &[data-background-color='green'] {
        background-color: $success-color;
    }

    .image {
        height: 200px;
        overflow: hidden;
        position: relative;
    }

    .avatar {
        border-radius: 50%;
        height: 30px;
        margin-bottom: 15px;
        overflow: hidden;
        width: 30px;
    }

    .numbers {
        font-size: 2em;
    }

    .big-title {
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 15px;
        text-align: center;
    }

    label {
        color: $dark-gray;
        font-size: $font-size-small;
        margin-bottom: 5px;
    }

    .card-footer {
        background-color: transparent;
        border: 0;

        .stats {
            i {
                color: $default-color;
                margin-right: 5px;
                position: relative;
                top: 0px;
            }
        }

        .btn {
            margin: 0;
        }
    }

    &.card-plain {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;

        .card-body {
            padding-left: 5px;
            padding-right: 5px;
        }

        img {
            border-radius: $border-radius-extreme;
        }
    }
}
