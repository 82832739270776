@mixin img-fluid {
    height: auto;
    max-width: 100%;
}

@mixin museo-sans {
    font-family: $op-museo-sans;
    font-weight: 300;
}

@mixin museo-display {
    font-family: $op-museo-sans-display;
    font-weight: 900;
}

@mixin grayscale($value) {
    -webkit-filter: #{'grayscale(#{$value})'};
    -moz-filter: #{'grayscale(#{$value})'};
    filter: #{'grayscale(#{$value})'};
}

@mixin modal {
    @media screen and (min-width: 768px) {
        .modal-close {
            color: white;
            cursor: pointer;
            font-size: 1.25rem;
            position: fixed;
            right: 1.5rem;
            top: 1.5rem;
            span {
                display: block;
            }
            .icon {
                display: none;
                width: 3rem;
                &.white {
                    display: block;
                }
            }
        }
    }
    .modal-close {
        cursor: pointer;
        font-size: 1.25rem;
        position: absolute;
        right: 0.75rem;
        top: 1.25rem;
        span {
            display: none;
        }
        .icon {
            display: block;
            width: 2rem;
            &.white {
                display: none;
            }
        }
    }
    h3 {
        color: var(--header-blue);
        font-size: 1.375rem;
        font-weight: 900;
        @include museo-display;
    }

    label {
        font-size: 1rem;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .submit {
        background-color: var(--survey-blue) !important;
        border-radius: 4px;
        margin: 0 0.5rem;
        padding: 1rem 3rem;
        text-transform: none;

        &:disabled {
            cursor: not-allowed !important;
        }

        &:hover,
        &:focus {
            background-color: var(--hover-blue) !important;
        }
    }
}

@import '../variables';

/* floorplan font stacks, updated*/
$op-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$op-serif: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida, 'DejaVu Serif', 'Bitstream Vera Serif',
    'Liberation Serif', Georgia, serif;
$op-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$op-museo-sans: join('museo-sans', $op-sans-serif, comma);
$op-museo-sans-rounded: join('museo-sans-rounded', $op-museo-sans, comma);
$op-museo-sans-display: join('museo-sans-display', $op-museo-sans, comma);

/* font size base overriding paper*/
$font-size-base: 16px;

/*Colors*/
$light-grey: #f7f8fa;
$nav-link-color: #5c80aa;
$nav-link-active-color: #2a425e;
$nav-link-hover-color: #ec7625;
$muted-text-color: #7f8fa4;

.main-panel {
    min-height: 100%;

    @media (min-width: 1960px) {
        width: 85% !important;
    }
}

p {
    @media (min-width: 1960px) {
        font-size: 14pt;
    }
}

.sidebar {
    border-right: none !important;

    .logo {
        &:after {
            height: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .sidebar {
        .nav {
            li {
                >a:not([data-toggle='collapse']) {
                    color: $nav-link-color;
                }

                &.active {
                    background-color: $light-grey;

                    >a:not([data-toggle='collapse']) {
                        color: $nav-link-active-color;
                    }
                }
            }
        }

        .sidebar-wrapper {
            li.active {
                >a:not([data-toggle='collapse']) {
                    &:before {
                        background-color: transparent;
                        border: none;
                        border-bottom-right-radius: 25px;
                        box-shadow: 0 25px 0 0 $light-grey;
                        content: '';
                        height: 50px;
                        position: absolute;
                        top: -50px;
                        width: 25px;
                    }

                    &:after {
                        background-color: transparent;
                        border: none;
                        border-top-right-radius: 25px;
                        box-shadow: 0 -25px 0 0 $light-grey;
                        content: '';
                        height: 50px;
                        position: absolute;
                        top: 50px;
                        width: 25px;
                    }
                }
            }
        }
    }
}

@import '../../../../../../node_modules/@offerpad/floorplan/dist/settings/Colors';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/settings/Fonts';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/settings/Measures';

@import '../../../../../../node_modules/@offerpad/floorplan/dist/vendor/typey/typey';

@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/BoxShadow';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/Container';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/Contrast';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/FluidImage';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/Gradient';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/Headline';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/MediaQuery';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/ScreenReader';
@import '../../../../../../node_modules/@offerpad/floorplan/dist/mixins/State';

/* polaris-style colors*/
$color-palette-data: (
    white: (
        base: #ffffff
    ),
    orange: (
        lighter: #eda06d,
        light: #ed9255,
        base: #ed843e,
        dark: #ea711e,
        darker: #cc5e12
    ),
    teal: (
        lighter: #7bd1e1,
        light: #52c2d7,
        base: #2eb0c8,
        dark: #258b9e,
        darker: #1b6775
    ),
    blue: (
        lighter: #adc2da,
        light: #81a1c6,
        base: #5581b2,
        dark: #3e618a,
        darker: #2a425e
    ),
    newblue: (
        lighter: #e0f3ff,
        light: #b8d4f5,
        base: #8fb2db,
        dark: #6b93c2,
        darker: #4c77a9
    ),
    yellow: (
        lighter: #ffeaa2,
        light: #ffd856,
        base: #ffc709,
        dark: #bc9100,
        darker: #6f5600
    ),
    gray: (
        lighter: #c4cfdc,
        light: #94a9c0,
        base: #6482a4,
        dark: #455c76,
        darker: #293646
    ),
    green: (
        lighter: #86e29b,
        light: #5dd879,
        base: #34ce57,
        dark: #28a745,
        darker: #1e7e34
    ),
    red: (
        lighter: #f3b7bd,
        light: #eb8c95,
        base: #e4606d,
        dark: #dc3545,
        darker: #bd2130
    )
);
@function color($hue, $value: base) {
    $fetched-color: map-get(map-get($color-palette-data, $hue), $value);
    @if map-has-key($color-palette-data, $fetched-color) {
        $fetched-color: map-get(
            map-get($color-palette-data, $fetched-color),
            $value
        );
    }
    @if type-of($fetched-color) ==color {
        @return $fetched-color;
    } @else {
        @error 'Color `#{$hue} - #{$value}` not found.';
    }
}
$state-color-palette: (
    neutral: gray,
    success: green,
    warning: yellow,
    danger: red
);
@function link-hover-focus($color) {
    @return darken($color, 15%);
}
/* layout colors */
$bg-color: color(white);
$bg-color-alt: transparentize(color(gray, lighter), 0.8);
$bg-color-dark: color(gray, darker);
$border-color: color(newblue, light);
/* text colors */
$text-color: color(gray, darker);
$text-color-muted: color(gray);
$text-color-light: color(white);
$headline-color: color(gray, darker);
$link-color: color(blue);
$link-color-hover: color(blue, dark);
$link-color-brand: color(orange, dark);
$link-color-brand-hover: color(orange, darker);
$link-color-light: color(gray, lighter);
$link-color-light-hover: color(white);
$placeholder-color: color(gray, light);
$code-bg-color: $bg-color-alt;
$code-text-color: color(red, dark);
/* miscellaneous colors */
$focus-color: color(orange, dark);
$focus-shadow: 0 0 10px opacify(color(blue, light), 0.5),
    0 0 5px color(blue, light);
$disabled-bg-color: color(gray, lighter);
.c-colors-container {
    display: grid;
    font-family: Arial, Helvetica, sans-serif;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    .c-color-label {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        font-weight: 600;
        justify-content: flex-end;
    }
    .c-color-div {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        height: 3rem;
        justify-content: center;
        width: 8rem;
    }
}
.u-orange-darker {
    background-color: color(orange, darker);
}
.u-orange-dark {
    background-color: color(orange, dark);
}
.u-orange-base {
    background-color: color(orange, base);
}
.u-orange-light {
    background-color: color(orange, light);
}
.u-orange-lighter {
    background-color: color(orange, lighter);
}
.u-teal-darker {
    background-color: color(teal, darker);
}
.u-teal-dark {
    background-color: color(teal, dark);
}
.u-teal-base {
    background-color: color(teal, base);
}
.u-teal-light {
    background-color: color(teal, light);
}
.u-teal-lighter {
    background-color: color(teal, lighter);
}
.u-blue-darker {
    background-color: color(blue, darker);
}
.u-blue-dark {
    background-color: color(blue, dark);
}
.u-blue-base {
    background-color: color(blue, base);
}
.u-blue-light {
    background-color: color(blue, light);
}
.u-blue-lighter {
    background-color: color(blue, lighter);
}
.u-newblue-darker {
    background-color: color(newblue, darker);
}
.u-newblue-dark {
    background-color: color(newblue, dark);
}
.u-newblue-base {
    background-color: color(newblue, base);
}
.u-newblue-light {
    background-color: color(newblue, light);
}
.u-newblue-lighter {
    background-color: color(newblue, lighter);
}
.u-yellow-darker {
    background-color: color(yellow, darker);
}
.u-yellow-dark {
    background-color: color(yellow, dark);
}
.u-yellow-base {
    background-color: color(yellow, base);
}
.u-yellow-light {
    background-color: color(yellow, light);
}
.u-yellow-lighter {
    background-color: color(yellow, lighter);
}
.u-gray-darker {
    background-color: color(gray, darker);
}
.u-gray-dark {
    background-color: color(gray, dark);
}
.u-gray-base {
    background-color: color(gray, base);
}
.u-gray-light {
    background-color: color(gray, light);
}
.u-gray-lighter {
    background-color: color(gray, lighter);
}
.u-green-darker {
    background-color: color(green, darker);
}
.u-green-dark {
    background-color: color(green, dark);
}
.u-green-base {
    background-color: color(green, base);
}
.u-green-light {
    background-color: color(green, light);
}
.u-green-lighter {
    background-color: color(green, lighter);
}
.u-red-darker {
    background-color: color(red, darker);
}
.u-red-dark {
    background-color: color(red, dark);
}
.u-red-base {
    background-color: color(red, base);
}
.u-red-light {
    background-color: color(red, light);
}
.u-red-lighter {
    background-color: color(red, lighter);
}

/* margin and padding util classes*/

$op-spacing: (
    z: 0,
    xs: 0.25,
    s: 0.5,
    m: 1,
    l: 2,
    xl: 4,
    xxl: 8
);

@each $prop in (margin, padding) {
    @each $size, $length in $op-spacing {
        .u-#{$prop}-#{$size} {
            #{$prop}: $length * 1.5rem !important;
        }
        .u-#{$prop}-t-#{$size},
        .u-#{$prop}-y-#{$size} {
            #{$prop}-top: $length * 1.5rem !important;
        }
        .u-#{$prop}-r-#{$size},
        .u-#{$prop}-x-#{$size} {
            #{$prop}-right: $length * 1.5rem !important;
        }
        .u-#{$prop}-b-#{$size},
        .u-#{$prop}-y-#{$size} {
            #{$prop}-bottom: $length * 1.5rem !important;
        }
        .u-#{$prop}-l-#{$size},
        .u-#{$prop}-x-#{$size} {
            #{$prop}-left: $length * 1.5rem !important;
        }
    }
}

.u-margin-auto {
    margin: auto !important;
}
.u-margin-t-auto,
.u-margin-y-auto {
    margin-top: auto !important;
}
.u-margin-r-auto,
.u-margin-x-auto {
    margin-right: auto !important;
}
.u-margin-b-auto,
.u-margin-y-auto {
    margin-bottom: auto !important;
}
.u-margin-l-auto,
.u-margin-x-auto {
    margin-left: auto !important;
}

/* spacing elements*/

@each $size, $length in $op-spacing {
    .u-spacer-#{$size} {
        display: block;
        height: $length * 1.5rem;
        width: 100%;
    }
}

/*!
 * FullCalendar v3.1.0 Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2016 Adam Shaw
 */

.fc {
    direction: ltr;
    text-align: left;
}

.fc-rtl {
    text-align: right;
}

body .fc {
    /* extra precedence to overcome jqui */
    font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #ddd;
}

.fc-unthemed .fc-popover {
    background-color: $white-color;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
    background: #196774;
    border-bottom: 0;
    color: #ffffff;
    .fc-title {
        font-weight: 700;
    }
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: $link-disabled-color;
}

.fc-unthemed .fc-today {
    background: $smoke-bg;
}

.fc-highlight {
    /* when user is selecting cells */
    background: #bce8f1;
    filter: alpha(opacity=30); /* for IE */
    opacity: 0.3;
}

.fc-bgevent {
    /* default look for background events */
    background: rgb(143, 223, 130);
    filter: alpha(opacity=30); /* for IE */
    opacity: 0.3;
}

.fc-nonbusiness {
    /* default look for non-business-hours areas */
    /* will inherit .fc-bgevent's styles */
    background: #d7d7d7;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/

.fc-icon {
    display: inline-block;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1em;
    height: 1em;
    line-height: 1em;
    overflow: hidden;
    text-align: center;

    /* don't allow browser text-selection */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
}

/*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/

.fc-icon:after {
    margin: 0 -1em; /* ensures character will be centered, regardless of width */
    position: relative;
}

.fc-icon-left-single-arrow:after {
    content: '\02039';
    font-size: 200%;
    font-weight: bold;
    left: 3%;
    top: -7%;
}

.fc-icon-right-single-arrow:after {
    content: '\0203A';
    font-size: 200%;
    font-weight: bold;
    left: -3%;
    top: -7%;
}

.fc-icon-left-double-arrow:after {
    content: '\000AB';
    font-size: 160%;
    top: -7%;
}

.fc-icon-right-double-arrow:after {
    content: '\000BB';
    font-size: 160%;
    top: -7%;
}

.fc-icon-left-triangle:after {
    content: '\25C4';
    font-size: 125%;
    left: -2%;
    top: 3%;
}

.fc-icon-right-triangle:after {
    content: '\25BA';
    font-size: 125%;
    left: 2%;
    top: 3%;
}

.fc-icon-down-triangle:after {
    content: '\25BC';
    font-size: 125%;
    top: 2%;
}

.fc-icon-x:after {
    content: '\000D7';
    font-size: 200%;
    top: 6%;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/

.fc button {
    background-color: #ffffff;
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 2px 5px #00000029;
    box-sizing: border-box;
    color: transparentize(#3e3d3d, 0.66);
    font-size: 1rem;
    padding: 0.5rem;
    // @include transition($fast-transition-time, linear);

    &:hover,
    &:focus {
        background-color: #ffffff;
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        outline: 0 !important;
        // @include box-shadow(none);
    }

    &.btn-icon {
        border-radius: 25px;
        padding: 6px 10px;
        i {
            margin-right: 0px;
        }
    }
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
    margin: 0;
    padding: 0;
}

.fc-state-default {
    /* non-theme */
    border: 1px solid;
}

/*.fc-state-default.fc-corner-left { */ /* non-theme */ /**/
/*	border-top-left-radius: 4px;*/
/*	border-bottom-left-radius: 4px;*/
/*}*/

/*.fc-state-default.fc-corner-right { */ /* non-theme */ /**/
/*	border-top-right-radius: 4px;*/
/*	border-bottom-right-radius: 4px;*/
/*}*/

/* icons in buttons */

.fc button .fc-icon {
    margin: 0;
    /* non-theme */
    position: relative;
    top: -0.05em; /* seems to be a good adjustment across browsers */
    vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/

.fc-state-hover,
.fc-state-active,
.fc-state-disabled {
    background-color: #e6e6e6;
    color: $black-color;
}

.fc button[disabled],
.fc button[disabled]:focus,
.fc button[disabled]:hover {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $font-color;
    cursor: default;
    // @include opacity(0.65);
}

.fc-state-hover {
    background-color: $smoke-bg;
}

.fc .fc-state-active,
.fc .fc-state-active:focus,
.fc .fc-state-active:hover,
.fc .fc-state-active:active:focus,
.fc .fc-state-active:active:hover,
.fc .fc-state-active:active {
    background-color: $default-color;
    color: $white-color;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/

.fc-button-group {
    display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/

.fc .fc-button-group > * {
    /* extra precedence b/c buttons have margin set to zero */
    float: left;
    margin: 0 2px 0 0;
}

.fc .fc-button-group > :first-child {
    /* same */
    margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/

.fc-popover {
    position: absolute;
    // @include box-shadow($dropdown-shadow);
}

.fc-popover .fc-header {
    padding: 0.25rem 0.5rem;
}

.fc-popover .fc-header .fc-title {
    margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
    cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
    float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
    float: right;
}

/* unthemed */

.fc-unthemed .fc-popover {
    border-style: solid;
    border-width: 1px;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: #ffffff;
    font-size: 1rem;
    margin-top: 2px;
    &::after {
        font-size: 100%;
        margin: 0;
    }
}

/* jqui themed */

.fc-popover > .ui-widget-header + .ui-widget-content {
    border-top: 0; /* where they meet, let the header have the border */
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/

.fc-divider {
    border-style: solid;
    border-width: 1px;
}

hr.fc-divider {
    border-width: 1px 0;
    height: 0;
    margin: 0;
    padding: 0 0 2px; /* height is unreliable across browsers, so use padding */
}

.fc-clear {
    clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
    left: 0;
    /* these element should always cling to top-left/right corners */
    position: absolute;
    right: 0;
    top: 0;
}

.fc-bg {
    bottom: 0; /* strech bg to bottom edge */
}

.fc-bg table {
    height: 100%; /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/

.fc table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em; /* normalize cross-browser */
    font-size: 0.875rem;
    table-layout: fixed;
    width: 100%;
}

.fc th {
    border-bottom-width: 1px;
    color: #4e4e4e;
    font-size: 0.75rem;
    font-weight: 300;
    padding-bottom: 3px;
    text-align: right;
}

.fc td {
    border-style: solid;
    border-width: 0;
    padding: 0;
    vertical-align: top;
}

.fc td.fc-today {
    border-style: double; /* overcome neighboring borders */
}

.fc .fc-widget-header {
    border: 0;
    border-bottom: 2px solid #d5d5d5;
}

.fc .fc-head .fc-widget-header {
    margin-top: $margin-base-vertical;
}
/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/

.fc .fc-row {
    /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
    /* no visible border by default. but make available if need be (scrollbar width compensation) */
    border-style: solid;
    border-width: 0;
}

.fc-row table {
    /* no bottom borders on rows */
    border-bottom: 0 hidden transparent;
    /* don't put left/right border on anything within a fake row.
	   the outer tbody will worry about this */
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
}

.fc-row:first-child table {
    border-top: 0 hidden transparent; /* no top border on first row */
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/

.fc-row {
    position: relative;
}

.fc-row .fc-bg {
    z-index: 1;
}

/* highlighting cells & background event skeleton */

.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
    bottom: 0; /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
    height: 100%; /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
    border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
    z-index: 2;
}

.fc-row .fc-highlight-skeleton {
    z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/

.fc-row .fc-content-skeleton {
    padding-bottom: 2px; /* matches the space above the events */
    position: relative;
    z-index: 4;
}

.fc-row .fc-helper-skeleton {
    z-index: 5;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
    /* see-through to the background below */
    background: none; /* in case <td>s are globally styled */

    /* don't put a border between events and/or the day number */
    border-bottom: 0;
    border-color: transparent;
}

.fc-row .fc-content-skeleton tbody td, /* cells with events inside (so NOT the day number cell) */
.fc-row .fc-helper-skeleton tbody td {
    /* don't put a border between event cells */
    border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/

.fc-scroller {
    overflow-x: hidden;
    /* this class goes on elements for guaranteed vertical scrollbars */
    overflow-y: scroll;
}

.fc-scroller > * {
    overflow: hidden; /* don't let negative margins or absolute positioning create further scroll */
    /* we expect an immediate inner element */
    position: relative; /* re-scope all positions */
    width: 100%; /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/

.fc-event {
    background-color: #4e4e4e;
    border: 0;
    border-radius: 2px;
    color: #ffffff !important;
    cursor: pointer;
    display: block; /* make the <a> tag block */
    font-size: 0.85em;

    font-weight: normal; /* undo jqui's ui-widget-header bold */
    line-height: 1.3;
    position: relative; /* for resize handle and other inner positioning */

    &.event-azure {
        background-color: $light-azure;
    }
    &.event-green {
        background-color: $light-green;
    }
    &.event-orange {
        background-color: $light-orange;
    }
    &.event-red {
        background-color: $light-red;
    }
}

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
    color: $black-color; /* default TEXT color */
    text-decoration: none; /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
    cursor: pointer; /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed, /* causes a "warning" cursor. applied on body */
.fc-not-allowed .fc-event {
    /* to override an event's custom cursor */
    cursor: not-allowed;
}

.fc-event .fc-bg {
    background: $white-color;
    filter: alpha(opacity=25); /* for IE */
    opacity: 0.25;
    /* the generic .fc-bg already does position */
    z-index: 1;
}

.fc-event .fc-content {
    position: relative;
    z-index: 2;
}

.fc-event .fc-resizer {
    position: absolute;
    z-index: 3;
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */

.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
    margin-left: 0;
    padding-left: 1px; /* replace the border with padding */
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
    border-bottom-right-radius: 0;
    border-right-width: 0;
    border-top-right-radius: 0;
    margin-right: 0;
    padding-right: 1px; /* replace the border with padding */
}

/* resizer */

.fc-h-event .fc-resizer {
    bottom: -1px;
    left: -1px;
    right: -1px;
    /* positioned it to overcome the event's borders */
    top: -1px;
    width: 5px;
}

/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-ltr .fc-h-event .fc-start-resizer:before,
.fc-ltr .fc-h-event .fc-start-resizer:after,
.fc-rtl .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-end-resizer:before,
.fc-rtl .fc-h-event .fc-end-resizer:after {
    cursor: w-resize;
    right: auto; /* ignore the right and only use the left */
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-ltr .fc-h-event .fc-end-resizer:before,
.fc-ltr .fc-h-event .fc-end-resizer:after,
.fc-rtl .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-start-resizer:before,
.fc-rtl .fc-h-event .fc-start-resizer:after {
    cursor: e-resize;
    left: auto; /* ignore the left and only use the right */
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/

.fc-day-grid-event {
    margin: 2px 5px 0; /* spacing between events and edges */
    padding: 1px 2px;
}

.fc-day-grid-event .fc-content {
    overflow: hidden;
    text-overflow: ellipsis;
    /* force events to be one-line tall */
    white-space: nowrap;
}

.fc-day-grid-event .fc-time {
    font-weight: bold;
}

.fc-day-grid-event .fc-resizer {
    /* enlarge the default hit area */
    left: -3px;
    right: -3px;
    width: 7px;
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/

/* "more" link that represents hidden events */

.fc-more-cell {
    text-align: center;
}
a.fc-more {
    background-color: #196774;
    border: 1px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    height: 2em;
    line-height: 2em;
    margin: 0;
    text-decoration: none;
    width: 2em;
}
@media (min-width: 48rem) {
    a.fc-more {
        border-width: 2px;
        font-size: 1.25rem;
    }
}

a.fc-more:hover {
    text-decoration: underline;
}

.fc-limited {
    /* rows and cells that are hidden because of a "more" link */
    display: none;
}

/* popover that appears when "more" link is clicked */

.fc-day-grid .fc-row {
    z-index: 1; /* make the "more" popover one higher than this */
}

.fc-more-popover {
    width: 220px;
    z-index: 2;
}

.fc-more-popover .fc-event-container {
    padding: 10px;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/

.fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

@media (min-width: 48rem) {
    .fc-toolbar {
        padding-top: 2rem;
    }
}

.fc-toolbar .fc-left {
}

.fc-toolbar .fc-right {
}

.fc-toolbar .fc-center {
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
    /* extra precedence to override button border margins */
    float: left;
    margin-left: 0.75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
    /* extra precedence to override button border margins */
    margin-left: 0;
}

/* title text */

.fc-toolbar h2 {
    color: #4e4e4e;
    font-size: 1.5rem;
    margin: 0;
    // @include museo-display;
}
@media (min-width: 48rem) {
    .fc-toolbar h2 {
        font-size: 3rem;
    }
}

/* button layering (for border precedence) */

.fc-toolbar button {
    position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
    z-index: 2;
}

.fc-toolbar .fc-state-down {
    z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    z-index: 4;
}

.fc-toolbar button:focus {
    z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/

/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.fc-view, /* scope positioning and z-index's for everything within the view */
.fc-view > table {
    /* so dragged elements can be above the view's main element */
    position: relative;
    z-index: 1;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/

/* day row structure */

.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
    padding-bottom: 1em; /* ensure a space at bottom of cell for user selecting/clicking */
    /* we are sure there are no day numbers in these views, so... */
    padding-top: 1px; /* add a pixel to make sure there are 2px padding above events */
}

.fc-basic-view .fc-body .fc-row {
    min-height: 4em; /* ensure that all rows are at least this tall */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */

.fc-row.fc-rigid {
    overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

/* week and day number styling */

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
    padding: 0 2px;
}

.fc-basic-view td.fc-week-number span,
.fc-basic-view td.fc-day-number {
    padding: 8px;
}

.fc-basic-view .fc-week-number {
    text-align: center;
}

.fc-basic-view .fc-week-number span {
    /* work around the way we do column resizing and ensure a minimum width */
    display: inline-block;
    min-width: 1.25em;
}

.fc-ltr .fc-basic-view .fc-day-number {
    text-align: right;
}

.fc-rtl .fc-basic-view .fc-day-number {
    text-align: left;
}

.fc-day-number.fc-other-month {
    filter: alpha(opacity=30); /* for IE */
    opacity: 0.3;
    /* opacity with small font can sometimes look too faded
	   might want to set the 'color' property instead
	   making day-numbers bold also fixes the problem */
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/

.fc-agenda-view .fc-day-grid {
    position: relative;
    z-index: 2; /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
    min-height: 3em; /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
    padding-bottom: 1em; /* give space underneath events for clicking/selecting days */
    padding-top: 1px; /* add a pixel to make sure there are 2px padding above events */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/

.fc .fc-axis {
    padding: 0 4px;
    /* .fc to overcome default cell styles */
    vertical-align: middle;
    white-space: nowrap;
}

.fc-ltr .fc-axis {
    text-align: right;
}

.fc-rtl .fc-axis {
    text-align: left;
}

.ui-widget td.fc-axis {
    font-weight: normal; /* overcome jqui theme making it bold */
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/

.fc-time-grid-container, /* so scroll container's z-index is below all-day */
.fc-time-grid {
    /* so slats/bg/content/etc positions get scoped within here */
    position: relative;
    z-index: 1;
}

.fc-time-grid {
    min-height: 100%; /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table {
    /* don't put outer borders on slats/bg/content/etc */
    border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
    z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
    /* the <hr> AgendaView injects when grid is shorter than scroller */
    position: relative;
    z-index: 2;
}

.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fc-time-grid .fc-bgevent-skeleton {
    z-index: 3;
}

.fc-time-grid .fc-highlight-skeleton {
    z-index: 4;
}

.fc-time-grid .fc-content-skeleton {
    z-index: 5;
}

.fc-time-grid .fc-helper-skeleton {
    z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-slats td {
    border-bottom: 0; /* each cell is responsible for its top border */
    height: 1.5em;
}

.fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dotted;
}

.fc-time-grid .fc-slats .ui-widget-content {
    /* for jqui theme */
    background: none; /* see through to fc-bg */
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-highlight-container {
    /* a div within a cell within the fc-highlight-skeleton */
    position: relative; /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
    left: 0;
    position: absolute;
    right: 0;
    /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-event-container, /* a div within a cell within the fc-content-skeleton */
.fc-time-grid .fc-bgevent-container {
    /* a div within a cell within the fc-bgevent-skeleton */
    position: relative;
}

.fc-ltr .fc-time-grid .fc-event-container {
    /* space on the sides of events for LTR (default) */
    margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container {
    /* space on the sides of events for RTL */
    margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
    position: absolute;
    z-index: 1; /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
    /* background events always span full width */
    left: 0;
    right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/

.fc-v-event.fc-not-start {
    /* remove top rounded corners */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /* events that are continuing from another day */
    /* replace space made by the top border with padding */
    border-top-width: 0;
    padding-top: 1px;
}

.fc-v-event.fc-not-end {
    /* remove bottom rounded corners */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* replace space made by the top border with padding */
    border-bottom-width: 0;
    padding-bottom: 1px;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/

.fc-time-grid-event {
    overflow: hidden; /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
    padding: 0 1px;
}

.fc-time-grid-event .fc-time {
    font-size: 0.85em;
    white-space: nowrap;
}

/* short mode, where time and title are on the same line */

.fc-time-grid-event.fc-short .fc-content {
    /* don't wrap to second line (now that contents will be inline) */
    white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
    /* put the time and title on the same line */
    display: inline-block;
    vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
    display: none; /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
    content: attr(data-start); /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
    content: '\000A0-\000A0'; /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
    font-size: 0.85em; /* make the title text the same size as the time */
    padding: 0; /* undo padding from above */
}

/* resizer */

.fc-time-grid-event .fc-resizer {
    bottom: 0;
    cursor: s-resize;
    font-family: monospace;
    font-size: 11px;
    height: 8px;
    left: 0;
    line-height: 8px;
    overflow: hidden;
    right: 0;
    text-align: center;
}

.fc-time-grid-event .fc-resizer:after {
    content: '=';
}

.card-calendar {
    .content {
        padding: 0 !important;
    }
    .fc-toolbar {
        padding-left: $padding-dropdown-horizontal;
        padding-right: $padding-dropdown-horizontal;
        padding-top: $padding-dropdown-horizontal;
    }
    .fc td:first-child {
        border-left: 0;
    }
    .fc td:last-child {
        border-right: 0;
    }
    .fc-basic-view td:last-child.fc-week-number span,
    .fc-basic-view td:last-child.fc-day-number {
        padding-right: $padding-dropdown-horizontal;
    }
    .fc .fc-day-header:last-child {
        padding-right: 15px;
    }
    .fc .fc-row:last-child td {
        border-bottom: 0;
    }
    .fc .fc-body .fc-widget-content {
        border-bottom: 0;
    }
}

.fc-scroller {
    height: auto !important;
    overflow: auto !important;
}
