@mixin img-fluid {
    height: auto;
    max-width: 100%;
}

@mixin museo-sans {
    font-family: $op-museo-sans;
    font-weight: 300;
}

@mixin museo-display {
    font-family: $op-museo-sans-display;
    font-weight: 900;
}

@mixin grayscale($value) {
    -webkit-filter: #{'grayscale(#{$value})'};
    -moz-filter: #{'grayscale(#{$value})'};
    filter: #{'grayscale(#{$value})'};
}

@mixin modal {
    @media screen and (min-width: 768px) {
        .modal-close {
            color: white;
            cursor: pointer;
            font-size: 1.25rem;
            position: fixed;
            right: 1.5rem;
            top: 1.5rem;
            span {
                display: block;
            }
            .icon {
                display: none;
                width: 3rem;
                &.white {
                    display: block;
                }
            }
        }
    }
    .modal-close {
        cursor: pointer;
        font-size: 1.25rem;
        position: absolute;
        right: 0.75rem;
        top: 1.25rem;
        span {
            display: none;
        }
        .icon {
            display: block;
            width: 2rem;
            &.white {
                display: none;
            }
        }
    }
    h3 {
        color: var(--header-blue);
        font-size: 1.375rem;
        font-weight: 900;
        @include museo-display;
    }

    label {
        font-size: 1rem;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .submit {
        background-color: var(--survey-blue) !important;
        border-radius: 4px;
        margin: 0 0.5rem;
        padding: 1rem 3rem;
        text-transform: none;

        &:disabled {
            cursor: not-allowed !important;
        }

        &:hover,
        &:focus {
            background-color: var(--hover-blue) !important;
        }
    }
}
