.wrapper {
    height: 100vh;
    position: relative;
    top: 0;

    &.wrapper-full-page {
        height: 100%;
        min-height: 100vh;
    }
}

.sidebar,
.off-canvas-sidebar {
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 260px;
    z-index: 995;

    @media (min-width: 1960px) {
        width: 15% !important;
    }

    .sidebar-wrapper {
        height: calc(100vh - 75px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 100px;
        position: relative;
        width: 260px;
        z-index: 4;

        @media (min-width: 1960px) {
            width: 100% !important;
        }

        .pl-5 {
            @media (min-width: 1960px) and (max-width: 2939px) {
                font-size: 1.5rem !important;
            }

            @media (min-width: 2940px) {
                font-size: 1.75rem !important;
            }
        }

        .dropdown .dropdown-backdrop {
            display: none !important;
        }

        .navbar-form {
            border: none;
        }

        > .nav,
        .user .info {
            [data-toggle='collapse'] ~ div > ul > li > a {
                span {
                    display: inline-block;
                    @extend .animation-transition-general;
                }

                .sidebar-normal {
                    display: block;
                    line-height: 20px;
                    margin: 0;
                    opacity: 1;
                    position: relative;
                    transform: translateX(0px);
                    white-space: nowrap;
                    z-index: 1;
                }

                .sidebar-mini-icon {
                    color: $opacity-5;
                    display: inherit;
                    float: left;
                    font-size: 12px;
                    line-height: 20px;
                    margin-left: 0px;
                    margin-right: 10px;
                    position: relative;
                    text-align: center;
                    width: 34px;
                    z-index: 1;
                }

                .sidebar-normal {
                    text-transform: capitalize;
                }

                i {
                    font-size: 17px;
                    line-height: 20px;
                    width: 26px;
                }
            }
        }
    }

    .navbar-minimize {
        opacity: 1;
        position: absolute;
        right: 20px;
        top: 2px;

        @extend .animation-transition-general;
    }
    .logo-tim {
        border: 1px solid #333;
        border-radius: 50%;
        display: block;
        float: left;
        height: 61px;
        overflow: hidden;
        width: 61px;

        img {
            height: 60px;
            width: 60px;
        }
    }

    .nav {
        display: block;
        margin-top: 20px;

        .caret {
            position: absolute;
            right: 10px;
            top: 14px;
        }

        li {
            > a + div .nav li > a {
                margin-top: 7px;
            }

            > a {
                color: $white-color;
                cursor: pointer;
                display: block;
                font-size: 14px;
                line-height: 30px;
                margin: 10px 15px 0;
                opacity: 0.7;
                padding: 10px 8px;
                position: relative;
                text-decoration: none;
            }

            .nav > li > a {
                padding: 5px 8px;
            }

            &.active > a,
            &.active > a > i {
                opacity: 1;
                /* color: $danger-color;*/
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a {
                opacity: 1;
            }

            &.active > a[data-toggle='collapse'] > {
                background-color: transparent;
                box-shadow: $box-shadow;
            }

            &.active > a[data-toggle='collapse'],
            &.active > a[data-toggle='collapse'] i,
            &.active > a[data-toggle='collapse'] ~ div > ul > li.active i,
            &.active > a[data-toggle='collapse'] ~ div > ul > li.active > a {
                color: $danger-color;

                & + div .nav .active a {
                    background-color: transparent;
                    box-shadow: none;

                    &:after {
                        content: '';
                        position: absolute;
                        width: calc(100% - 5px);
                        z-index: 0;
                    }
                }
            }

            &.active > a[data-toggle='collapse'] ~ div > .nav {
                margin-top: 0;
            }
        }

        p {
            display: block;
            font-weight: 600;
            height: auto;
            line-height: 30px;
            margin: 0;
            position: relative;
            white-space: nowrap;
            @extend .animation-transition-general;
        }

        i {
            color: $opacity-5;
            float: left;
            font-size: 24px;
            line-height: 30px;
            margin-right: 12px;
            position: relative;
            text-align: center;
            width: 34px;
        }

        .collapse-menu,
        .collapse,
        .collapsing {
            .nav {
                margin-top: 0;
            }
        }
    }

    .sidebar-background {
        background-position: center center;
        background-size: cover;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        &:after {
            background: #ffffff;
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            position: absolute;
            width: 100%;
            z-index: 3;
        }
    }

    .logo {
        padding: 7px $padding-base-horizontal;
        position: relative;
        z-index: 4;

        a.logo-mini,
        a.logo-normal {
            @extend .animation-transition-general;
        }

        a.logo-mini {
            float: left;
            margin-left: 10px;
            margin-right: 12px;
            opacity: 1;
            text-align: center;
            width: 34px;
        }

        a.logo-normal {
            display: block;
            opacity: 1;
            padding: 11px 0 8px;
            @include transform-translate-x(0px);
        }

        &:after {
            background-color: $opacity-5;
            bottom: 0;
            content: '';
            height: 1px;
            position: absolute;
            right: 15px;
            width: calc(100% - 30px);
        }

        p {
            color: $white-color;
            float: left;
            font-family: 'Muli', 'Helvetica', Arial, sans-serif;
            font-size: 20px;
            line-height: 20px;
            margin: 10px 10px;
        }

        .simple-text {
            color: $white-color;
            display: block;
            font-size: $font-size-large;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
            padding: $padding-base-vertical 0;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    .logo-tim {
        border: 1px solid #333;
        border-radius: 50%;
        display: block;
        float: left;
        height: 61px;
        overflow: hidden;
        width: 61px;

        img {
            height: 60px;
            width: 60px;
        }
    }

    &:before,
    &:after {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &:after {
        z-index: 3;
        @include icon-gradient($default-color);
    }

    &[data-color='white'] {
        @include sidebar-color($white-color);
        @include sidebar-text-color($default-color);
    }
    &[data-color='brown'] {
        @include sidebar-color($default-color);
    }
    &[data-color='black'] {
        @include sidebar-color($dark-color);
    }

    /* Active color changing*/

    &[data-active-color='primary'] {
        @include sidebar-active-color($primary-color);
    }
    &[data-active-color='info'] {
        @include sidebar-active-color($info-color);
    }
    &[data-active-color='success'] {
        @include sidebar-active-color($danger-color);
    }
    &[data-active-color='warning'] {
        @include sidebar-active-color($warning-color);
    }
    &[data-active-color='danger'] {
        @include sidebar-active-color($danger-color);
    }

    .user {
        margin: 20px auto 0;
        padding-bottom: 20px;
        position: relative;

        &:after {
            background-color: rgba(255, 255, 255, 0.3);
            bottom: 0;
            content: '';
            height: 1px;
            position: absolute;
            right: 15px;
            width: calc(100% - 30px);
        }

        .photo {
            border-radius: 50%;
            float: left;
            height: 34px;
            margin-left: 23px;
            margin-right: 10px;
            overflow: hidden;
            width: 34px;
            z-index: 5;
            @extend .animation-transition-general;

            img {
                width: 100%;
            }
        }

        a {
            color: $white-color;
            padding: $padding-base-vertical 15px;
            text-decoration: none;
            white-space: nowrap;
            @extend .animation-transition-general;
        }

        .info {
            > a {
                display: block;
                line-height: 18px;

                > span {
                    display: block;
                    opacity: 1;
                    position: relative;
                    @extend .animation-transition-general;
                }
            }

            .caret {
                position: absolute;
                right: 18px;
                top: 8px;
            }
        }
    }
}

.visible-on-sidebar-regular {
    display: inline-block !important;
}
.visible-on-sidebar-mini {
    display: none !important;
}

.off-canvas-sidebar {
    .nav {
        > li > a,
        > li > a:hover {
            color: $white-color;
        }

        > li > a:focus {
            background: rgba(200, 200, 200, 0.2);
        }
    }
}

/*Override*/
.main-panel {
    background-color: $light-grey;
    float: right;
    position: relative;
    width: $sidebar-width;

    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > .content {
        margin-top: 93px;
        min-height: calc(100vh - 123px);
        padding: 0 30px 30px;
    }

    > .navbar {
        margin-bottom: 0;
    }

    .header {
        margin-bottom: 50px;

        @media (max-width: 991px) {
            margin-bottom: 1.5rem;
        }
    }
}

.perfect-scrollbar-on {
    .sidebar,
    .main-panel {
        height: 100%;
        max-height: 100%;
    }
}

@media (min-width: 991px) {
    .sidebar,
    .main-panel,
    .sidebar-wrapper {
        -webkit-overflow-scrolling: touch;
        -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
        transition-duration: 0.2s, 0.2s, 0.35s;
        -webkit-transition-property: top, bottom, width;
        transition-property: top, bottom, width;
        -webkit-transition-timing-function: linear, linear, ease;
        transition-timing-function: linear, linear, ease;
    }

    .sidebar-mini {
        .visible-on-sidebar-regular {
            display: none !important;
        }
        .visible-on-sidebar-mini {
            display: inline-block !important;
        }

        .sidebar,
        .sidebar .sidebar-wrapper {
            width: 80px;
        }

        .main-panel {
            width: $sidebar-mini-width;
        }

        .sidebar {
            display: block;
            z-index: 1030;

            .logo {
                a.logo-normal {
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
                a.logo-mini {
                    opacity: 1;
                }
            }

            .sidebar-wrapper {
                > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .info > a > span,
                > .nav li > a p {
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
            }
        }

        .sidebar:hover {
            width: 260px;

            .logo {
                a.logo-normal {
                    opacity: 1;
                    @include transform-translate-x(0);
                }
            }

            .navbar-minimize {
                opacity: 1;
            }
            .sidebar-wrapper {
                width: 260px;

                > .nav li > a p,
                > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .info > a > span {
                    opacity: 1;
                    @include transform-translate-x(0px);
                }
            }
        }
    }
}

.panel-header {
    background: #141e30; /* fallback for old browsers */
    background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
    background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
    height: 260px;
    overflow: hidden;
    padding-bottom: 45px;
    padding-top: 80px;
    position: relative;

    .header {
        .title {
            color: $white-color;
        }
        .category {
            color: $opacity-5;
            font-size: 13px;
            margin: 0 auto;
            max-width: 600px;

            a {
                color: $white-color;
            }
        }
    }
}

.panel-header-sm {
    height: 135px;
}

.panel-header-lg {
    height: 380px;
}

/* Stying for logos*/

.sidebar .logo .simple-text .logo-img {
    align-items: center;
    background: #fff;
    border-radius: 40px;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-left: 0;
    margin-right: 10px;
    text-align: center;
    width: 34px;

    img {
        height: 19px;
    }
}

.sidebar {
    &[data-color='white'] {
        border-right: 1px solid #ddd;
    }
}
