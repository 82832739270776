@mixin img-fluid {
    height: auto;
    max-width: 100%;
}

@mixin museo-sans {
    font-family: $op-museo-sans;
    font-weight: 300;
}

@mixin museo-display {
    font-family: $op-museo-sans-display;
    font-weight: 900;
}

@mixin grayscale($value) {
    -webkit-filter: #{'grayscale(#{$value})'};
    -moz-filter: #{'grayscale(#{$value})'};
    filter: #{'grayscale(#{$value})'};
}

@mixin modal {
    @media screen and (min-width: 768px) {
        .modal-close {
            color: white;
            cursor: pointer;
            font-size: 1.25rem;
            position: fixed;
            right: 1.5rem;
            top: 1.5rem;
            span {
                display: block;
            }
            .icon {
                display: none;
                width: 3rem;
                &.white {
                    display: block;
                }
            }
        }
    }
    .modal-close {
        cursor: pointer;
        font-size: 1.25rem;
        position: absolute;
        right: 0.75rem;
        top: 1.25rem;
        span {
            display: none;
        }
        .icon {
            display: block;
            width: 2rem;
            &.white {
                display: none;
            }
        }
    }
    h3 {
        color: var(--header-blue);
        font-size: 1.375rem;
        font-weight: 900;
        @include museo-display;
    }

    label {
        font-size: 1rem;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .submit {
        background-color: var(--survey-blue) !important;
        border-radius: 4px;
        margin: 0 0.5rem;
        padding: 1rem 3rem;
        text-transform: none;

        &:disabled {
            cursor: not-allowed !important;
        }

        &:hover,
        &:focus {
            background-color: var(--hover-blue) !important;
        }
    }
}

@import 'paper/variables';

/* floorplan font stacks, updated*/
$op-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
$op-serif: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida, 'DejaVu Serif', 'Bitstream Vera Serif',
    'Liberation Serif', Georgia, serif;
$op-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$op-museo-sans: join('museo-sans', $op-sans-serif, comma);
$op-museo-sans-rounded: join('museo-sans-rounded', $op-museo-sans, comma);
$op-museo-sans-display: join('museo-sans-display', $op-museo-sans, comma);

/* font size base overriding paper*/
$font-size-base: 16px;

/*Colors*/
$light-grey: #f7f8fa;
$nav-link-color: #5c80aa;
$nav-link-active-color: #2a425e;
$nav-link-hover-color: #ec7625;
$muted-text-color: #7f8fa4;

.main-panel {
    min-height: 100%;

    @media (min-width: 1960px) {
        width: 85% !important;
    }
}

p {
    @media (min-width: 1960px) {
        font-size: 14pt;
    }
}

.sidebar {
    border-right: none !important;

    .logo {
        &:after {
            height: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .sidebar {
        .nav {
            li {
                >a:not([data-toggle='collapse']) {
                    color: $nav-link-color;
                }

                &.active {
                    background-color: $light-grey;

                    >a:not([data-toggle='collapse']) {
                        color: $nav-link-active-color;
                    }
                }
            }
        }

        .sidebar-wrapper {
            li.active {
                >a:not([data-toggle='collapse']) {
                    &:before {
                        background-color: transparent;
                        border: none;
                        border-bottom-right-radius: 25px;
                        box-shadow: 0 25px 0 0 $light-grey;
                        content: '';
                        height: 50px;
                        position: absolute;
                        top: -50px;
                        width: 25px;
                    }

                    &:after {
                        background-color: transparent;
                        border: none;
                        border-top-right-radius: 25px;
                        box-shadow: 0 -25px 0 0 $light-grey;
                        content: '';
                        height: 50px;
                        position: absolute;
                        top: 50px;
                        width: 25px;
                    }
                }
            }
        }
    }
}

@import '../../../../node_modules/@offerpad/floorplan/dist/settings/Colors';
@import '../../../../node_modules/@offerpad/floorplan/dist/settings/Fonts';
@import '../../../../node_modules/@offerpad/floorplan/dist/settings/Measures';

@import '../../../../node_modules/@offerpad/floorplan/dist/vendor/typey/typey';

@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/BoxShadow';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/Container';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/Contrast';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/FluidImage';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/Gradient';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/Headline';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/MediaQuery';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/ScreenReader';
@import '../../../../node_modules/@offerpad/floorplan/dist/mixins/State';

/* polaris-style colors*/
$color-palette-data: (
    white: (
        base: #ffffff
    ),
    orange: (
        lighter: #eda06d,
        light: #ed9255,
        base: #ed843e,
        dark: #ea711e,
        darker: #cc5e12
    ),
    teal: (
        lighter: #7bd1e1,
        light: #52c2d7,
        base: #2eb0c8,
        dark: #258b9e,
        darker: #1b6775
    ),
    blue: (
        lighter: #adc2da,
        light: #81a1c6,
        base: #5581b2,
        dark: #3e618a,
        darker: #2a425e
    ),
    newblue: (
        lighter: #e0f3ff,
        light: #b8d4f5,
        base: #8fb2db,
        dark: #6b93c2,
        darker: #4c77a9
    ),
    yellow: (
        lighter: #ffeaa2,
        light: #ffd856,
        base: #ffc709,
        dark: #bc9100,
        darker: #6f5600
    ),
    gray: (
        lighter: #c4cfdc,
        light: #94a9c0,
        base: #6482a4,
        dark: #455c76,
        darker: #293646
    ),
    green: (
        lighter: #86e29b,
        light: #5dd879,
        base: #34ce57,
        dark: #28a745,
        darker: #1e7e34
    ),
    red: (
        lighter: #f3b7bd,
        light: #eb8c95,
        base: #e4606d,
        dark: #dc3545,
        darker: #bd2130
    )
);
@function color($hue, $value: base) {
    $fetched-color: map-get(map-get($color-palette-data, $hue), $value);
    @if map-has-key($color-palette-data, $fetched-color) {
        $fetched-color: map-get(
            map-get($color-palette-data, $fetched-color),
            $value
        );
    }
    @if type-of($fetched-color) ==color {
        @return $fetched-color;
    } @else {
        @error 'Color `#{$hue} - #{$value}` not found.';
    }
}
$state-color-palette: (
    neutral: gray,
    success: green,
    warning: yellow,
    danger: red
);
@function link-hover-focus($color) {
    @return darken($color, 15%);
}
/* layout colors */
$bg-color: color(white);
$bg-color-alt: transparentize(color(gray, lighter), 0.8);
$bg-color-dark: color(gray, darker);
$border-color: color(newblue, light);
/* text colors */
$text-color: color(gray, darker);
$text-color-muted: color(gray);
$text-color-light: color(white);
$headline-color: color(gray, darker);
$link-color: color(blue);
$link-color-hover: color(blue, dark);
$link-color-brand: color(orange, dark);
$link-color-brand-hover: color(orange, darker);
$link-color-light: color(gray, lighter);
$link-color-light-hover: color(white);
$placeholder-color: color(gray, light);
$code-bg-color: $bg-color-alt;
$code-text-color: color(red, dark);
/* miscellaneous colors */
$focus-color: color(orange, dark);
$focus-shadow: 0 0 10px opacify(color(blue, light), 0.5),
    0 0 5px color(blue, light);
$disabled-bg-color: color(gray, lighter);
.c-colors-container {
    display: grid;
    font-family: Arial, Helvetica, sans-serif;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    .c-color-label {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        font-weight: 600;
        justify-content: flex-end;
    }
    .c-color-div {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        height: 3rem;
        justify-content: center;
        width: 8rem;
    }
}
.u-orange-darker {
    background-color: color(orange, darker);
}
.u-orange-dark {
    background-color: color(orange, dark);
}
.u-orange-base {
    background-color: color(orange, base);
}
.u-orange-light {
    background-color: color(orange, light);
}
.u-orange-lighter {
    background-color: color(orange, lighter);
}
.u-teal-darker {
    background-color: color(teal, darker);
}
.u-teal-dark {
    background-color: color(teal, dark);
}
.u-teal-base {
    background-color: color(teal, base);
}
.u-teal-light {
    background-color: color(teal, light);
}
.u-teal-lighter {
    background-color: color(teal, lighter);
}
.u-blue-darker {
    background-color: color(blue, darker);
}
.u-blue-dark {
    background-color: color(blue, dark);
}
.u-blue-base {
    background-color: color(blue, base);
}
.u-blue-light {
    background-color: color(blue, light);
}
.u-blue-lighter {
    background-color: color(blue, lighter);
}
.u-newblue-darker {
    background-color: color(newblue, darker);
}
.u-newblue-dark {
    background-color: color(newblue, dark);
}
.u-newblue-base {
    background-color: color(newblue, base);
}
.u-newblue-light {
    background-color: color(newblue, light);
}
.u-newblue-lighter {
    background-color: color(newblue, lighter);
}
.u-yellow-darker {
    background-color: color(yellow, darker);
}
.u-yellow-dark {
    background-color: color(yellow, dark);
}
.u-yellow-base {
    background-color: color(yellow, base);
}
.u-yellow-light {
    background-color: color(yellow, light);
}
.u-yellow-lighter {
    background-color: color(yellow, lighter);
}
.u-gray-darker {
    background-color: color(gray, darker);
}
.u-gray-dark {
    background-color: color(gray, dark);
}
.u-gray-base {
    background-color: color(gray, base);
}
.u-gray-light {
    background-color: color(gray, light);
}
.u-gray-lighter {
    background-color: color(gray, lighter);
}
.u-green-darker {
    background-color: color(green, darker);
}
.u-green-dark {
    background-color: color(green, dark);
}
.u-green-base {
    background-color: color(green, base);
}
.u-green-light {
    background-color: color(green, light);
}
.u-green-lighter {
    background-color: color(green, lighter);
}
.u-red-darker {
    background-color: color(red, darker);
}
.u-red-dark {
    background-color: color(red, dark);
}
.u-red-base {
    background-color: color(red, base);
}
.u-red-light {
    background-color: color(red, light);
}
.u-red-lighter {
    background-color: color(red, lighter);
}

/* margin and padding util classes*/

$op-spacing: (
    z: 0,
    xs: 0.25,
    s: 0.5,
    m: 1,
    l: 2,
    xl: 4,
    xxl: 8
);

@each $prop in (margin, padding) {
    @each $size, $length in $op-spacing {
        .u-#{$prop}-#{$size} {
            #{$prop}: $length * 1.5rem !important;
        }
        .u-#{$prop}-t-#{$size},
        .u-#{$prop}-y-#{$size} {
            #{$prop}-top: $length * 1.5rem !important;
        }
        .u-#{$prop}-r-#{$size},
        .u-#{$prop}-x-#{$size} {
            #{$prop}-right: $length * 1.5rem !important;
        }
        .u-#{$prop}-b-#{$size},
        .u-#{$prop}-y-#{$size} {
            #{$prop}-bottom: $length * 1.5rem !important;
        }
        .u-#{$prop}-l-#{$size},
        .u-#{$prop}-x-#{$size} {
            #{$prop}-left: $length * 1.5rem !important;
        }
    }
}

.u-margin-auto {
    margin: auto !important;
}
.u-margin-t-auto,
.u-margin-y-auto {
    margin-top: auto !important;
}
.u-margin-r-auto,
.u-margin-x-auto {
    margin-right: auto !important;
}
.u-margin-b-auto,
.u-margin-y-auto {
    margin-bottom: auto !important;
}
.u-margin-l-auto,
.u-margin-x-auto {
    margin-left: auto !important;
}

/* spacing elements*/

@each $size, $length in $op-spacing {
    .u-spacer-#{$size} {
        display: block;
        height: $length * 1.5rem;
        width: 100%;
    }
}

$minHeight: 42rem;
body, html {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.c-authform {
    flex: 1;
    border-radius: 0.5rem;
    margin: -2rem 1.6875rem 0 1.6875rem;
    padding: 1.5rem;
    background: #ffffff;
    box-shadow: 0px -9px 20px #29364634;

    &__submit {
        background-color: #eb7724;
        border-radius: 0.25rem;
        font-size: 1.0625rem;
        font-weight: 900;
        margin-bottom: 1.5rem;
        text-transform: none;
    }

    a {
        color: $nav-link-color;
        font-weight: 700;
        &.underlined-link {
            text-decoration: underline;
        }
    }

    &__forgot {
        text-align: left;
        font-size: 0.75rem;
        margin-bottom: 1rem;
    }
}

.c-authform__infopanel {
    padding: 1.5rem 3rem;
    width: 100%;
    color: white;
    background-image: url('/src/assets/icons/sign-up-bg.svg');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 100% 40%;
    min-height: 15.5rem;

    &__option {
        padding: 0.5rem 0rem;
        font-size: 1.0625rem;
        font-weight: 700;
        animation: option-animation 400ms ease-out;
    }

    padding-bottom: 3rem;
}

.option-component {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

@include bp-desktop-xlarge {
    .c-authform__container {
        display: flex;
        box-shadow: 0px -9px 20px #29364634;
        border-radius: 0.5rem;
        margin-top: -5rem;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
        min-height: $minHeight;
        .login {
            flex: 1;
            padding: 6rem 5.5rem;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
        .preloaded-text {
            font-size: 1.3125rem;
            margin-bottom: 2.3rem;
        }

        .register {
            flex: 1;
            padding: 1.5rem 2.25rem 0.5rem 2.25rem;
        }
        .c-authform {
            border-radius: 0 0.5rem 0.5rem 0;
            background: #ffffff;
            margin: 0;
            box-shadow: none;
            &__submit {
                background-color: #eb7724;
                border-radius: 0.25rem;
                font-size: 1.0625rem;
                font-weight: 900;
                margin-bottom: 1.5rem;
                text-transform: none;
            }

            a {
                color: $nav-link-color;
                font-weight: 700;
            }

            &__link {
                font-size: 0.875rem;
                text-align: center;
            }

            &__forgot {
                text-align: left;
            }
        }
        .c-authform__infopanel {
            padding: 0;
            width: 50%;
            color: white;
            border-radius: 0.5rem 0 0 0.5rem;
            background-image: url('/src/assets/icons/sign-up-bg.svg');
            background-repeat: no-repeat;
            background-size: 100%;

            header {
                margin: 2rem 0 2rem 2rem;
                padding: 2rem 0 2rem 3rem;
            }

            &__option {
                padding: 0.5rem 0 0.5rem 5.37rem;
                font-size: 1.3125rem;
                font-weight: 700;
                animation: none;
            }

            .highlight {
                color: #1b6775;
                background-color: white;
            }
        }
    }
}

@media only screen and (min-width: 1660px) {
    .c-authform__container {
        width: 80%;
        height: 50%vh;
    }
}

@keyframes option-animation {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.028;
    }

    20% {
        opacity: 0.104;
    }

    30% {
        opacity: 0.216;
    }

    40% {
        opacity: 0.352;
    }

    50% {
        opacity: 0.5;
    }

    60% {
        opacity: 0.648;
    }

    70% {
        opacity: 0.784;
    }

    80% {
        opacity: 0.896;
    }

    90% {
        opacity: 0.972;
    }

    100% {
        opacity: 1;
    }
}

.or-division-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    .hr-style {
        width: 40%;
        height: 2px;
        color: #aaa;
        display: inline;
        margin: 0.75rem 1rem;
    }
}

.formatted-name {
    display: flex;
    gap: 1.5rem;
    .name-component {
        flex: 1;
    }
}
