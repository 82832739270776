.op-card {
    background-color: #ffffff;
    border-radius: 0.5rem;
    color: #4e4e4e;
    margin-bottom: 1.75rem;
    padding: 1.5rem;
    width: 100%;
    &__title {
        color: #4e4e4e;
        font-size: 2rem;
        line-height: 1.1;
        margin: 0.5rem 0;
        @include museo-display;
    }
    &__small-title {
        color: #354052;
        font-size: 1rem;
        line-height: 1.1;
        margin: 0 0 0.5rem 0;
    }
    &__shadow {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
    }
    &__hoverable {
        transition: box-shadow 0.3s ease-in-out;
        &:hover {
            box-shadow: 0 10px 16px rgba(0, 0, 0, 0.08);
        }
    }
    &.no-round {
        border-radius: 0;
    }
}
